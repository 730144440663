import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";
// images
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'

import Chimney1 from '../../Images/Chimney1.jpeg'
import Chimney2 from '../../Images/Chimney2.jpeg'
import Chimney3 from '../../Images/Chimney3.jpeg'
import Chimney4 from '../../Images/Chimney4.jpeg'
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '../../Components/ServicesPage/AreaData'
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
import LocationData from '../LocationAll/LocationData'

export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Glendale, CA Chimney Sweep | Expert Chimney Cleaning, Inspection & Services | 747-349-8762</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Glendale Chimney Sweep | Expert Chimney Cleaning, Inspection & Services | 747-349-8762" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Glendale Chimney Sweep | Expert Chimney Cleaning, Inspection & Services | 747-349-8762" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweepglendale.org/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Glendale Chimney Sweep provides top-quality chimney cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your chimney service needs." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Glendale Chimney Sweep provides top-quality chimney cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your chimney service needs." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Glendale Chimney Sweep" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}

            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>The Chimney Experts You've Trusted for Over 50 Years in Glendale</h1>
                    <h2>Servicing Glendale And Surrounding Areas - Chimney Cleaning Near Me In Glendale, California</h2>
                    <a href="tel:747-349-8762">Call Us Now!</a>
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Chimney Cleaning Near Me In Glendale, California" /></a>
                </div>
                <div className="MainBannerRight">
                    <img src={bgnobgs} alt="Chimney Sweep In Glendale, CA | Chimney Repair In Glendale, CA | Chimney Services In Glendale, CA | Chimney Inspection In Glendale, CA" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="BookAnAppointmentMainComponentEmergery">
                <div className="BookAnAppointmentMainComponentRates">
                    <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={YELP} alt="Glendale Air Duct Cleaning Yelp Review" />
                        <img src={Angie} alt="Glendale Air Duct Cleaning Angie Review" />
                        <img src={HomeAdvisor} alt="Glendale Air Duct Cleaning HomeAdvisor Review" />
                        <img src={BBB} alt="Glendale Air Duct Cleaning BBB Review" />
                        <img src={Google} alt="Glendale Air Duct Cleaning Google Review" />
                        <img src={Porch} alt="Glendale Air Duct Cleaning Porch Review" />
                        <img src={Trust} alt="Glendale Air Duct Cleaning Trust Review" />
                    </div>
                    <h1>Customer Testimonials</h1>
                    <h2>⭐⭐⭐⭐⭐</h2>
                    <h3>
                        I’ve used Glendale Chimney Sweep for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.
                        <a href={`/reviews/${LocationData.find(a => a.City == ("Glendale").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>MORE FROM OUR CUSTOMERS &gt;</a>
                    </h3>

                </div>
                <span className="GoogleButt">
                    <h6>5 Stars - Based on 3,618 Reviews</h6>
                    <h6 className="GoogleButtBooms">
                        Within 5 mi • Within 20 mi • Open now • Residential • Top rated • Prices • Online estimates • Replacement
                    </h6>
                </span>
                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <a href="tel:747-349-8762">For emergency service call: 747-349-8762</a>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>OUR CHIMNEY SERVICES</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="/service/Chimney-Cleaning" className="MainBanner2TopBoxBox">
                            <img src={appcalendar} alt="Chimney Cleaning" />
                            <h3>Chimney Cleaning</h3>
                            <h2>Remove creosote buildup and debris for a safer, more efficient fireplace and improved indoor air quality.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Inspection" className="MainBanner2TopBoxBox">
                            <img src={appdropshipping} alt="Chimney Cleaning" />
                            <h3>Chimney Inspection</h3>
                            <h2>Ensure your chimney’s structural integrity and functionality with a thorough inspection to prevent hazards and issues.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Maintenance" className="MainBanner2TopBoxBox">
                            <img src={appsearch} alt="Chimney Cleaning" />
                            <h3>Chimney Maintenance</h3>
                            <h2>Regular maintenance prolongs chimney life, ensuring safety, efficiency, and preventing costly future repairs.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                        <a href="/service/Chimney-Repair" className="MainBanner2TopBoxBox">
                            <img src={appwallet} alt="Chimney Cleaning" />
                            <h3>Chimney Repair</h3>
                            <h2>Fix cracks, leaks, and structural issues to restore your chimney’s safety, functionality, and aesthetic appeal.</h2>
                            <h4>EXPLORE THIS SERVICE</h4>
                        </a>
                    </div>
                </div>
            </div>

            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={Chimney3} alt="Chimney Sweep In Glendale, CA | Chimney Repair In Glendale, CA | Chimney Services In Glendale, CA | Chimney Inspection In Glendale, CA" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <h4>LEAVE CHIMNEY CARE TO THE PROS</h4>
                        <h5>BECAUSE WITH Glendale CHIMNEY SWEEP, YOU GET MORE THAN JUST A SERVICE—YOU GET PEACE OF MIND.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>REPUTATION</h2>
                            <h3>Trusted chimney experts since 1975 In Glendale, California. Delivering exceptional service and craftsmanship with decades of proven expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TRANSPARENCY</h2>
                            <h3>No hidden fees—our honest pricing covers chimney services any time, including holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>EFFICIENCY</h2>
                            <h3>Prompt same-day and emergency chimney services In Glendale, California, available 365 days a year to meet your needs.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>QUALITY</h2>
                            <h3>Comprehensive chimney care, from cleaning to repair, using advanced techniques and the latest equipment.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <div className="MainBanner2TopTopBtn">
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Chimney Sweep In Glendale, CA | Chimney Repair In Glendale, CA | Chimney Services In Glendale, CA | Chimney Inspection In Glendale, CA" /></a>
                    <a href="tel:747-349-8762">CALL NOW 747-349-8762</a>
                </div>
            </div>
            <div className="MainBanner3">
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Cleaning Glendale, CA</h2>
                            <h3>
                                Exceptional Chimney Cleaning Services in Glendale, CA
                                <br />
                                <br />
                                Welcome to Glendale Sweep, your trusted provider of professional chimney cleaning services in Glendale, CA. Maintaining a clean chimney is essential for the safety and efficiency of your fireplace or stove. Over time, soot, creosote, and other debris accumulate inside your chimney, posing fire hazards and reducing efficiency. Our expert technicians are committed to ensuring your chimney remains clean and safe for use.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Cleaning">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney1} alt="Chimney Cleaning Glendale, CA" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">

                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Inspection Glendale, CA</h2>
                            <h3>
                                Exceptional Chimney Inspection Services in Glendale, CA
                                <br />
                                <br />
                                Welcome to Glendale Sweep, your trusted provider of professional chimney inspection services in Glendale, CA. Regular chimney inspections are crucial for the safety and efficiency of your fireplace or stove. Over time, issues such as soot buildup, creosote accumulation, and structural concerns can pose significant fire hazards and reduce efficiency. Our expert technicians are dedicated to thoroughly inspecting your chimney to ensure it remains safe and in optimal condition for use.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Inspection">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney2} alt="Chimney Inspection Glendale, CA" />
                        </div>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Repair Glendale, CA</h2>
                            <h3>
                                Professional Chimney Repair Services in Glendale, CA
                                <br />
                                <br />
                                At Glendale Sweep, we offer expert chimney repair services to homeowners in Glendale, CA. A well-maintained chimney is essential for the safety and efficiency of your fireplace or stove. Over time, your chimney can suffer from wear and tear, weather damage, and other issues that can compromise its integrity. Our skilled technicians are here to provide comprehensive chimney repairs, ensuring your chimney remains safe and functional.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Repair">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney3} alt="Chimney Repair Glendale, CA" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Maintenance Glendale, CA</h2>
                            <h3>
                                Top-Notch Chimney Maintenance Services in Glendale, CA
                                <br />
                                <br />
                                At Glendale Sweep, we specialize in providing comprehensive chimney maintenance services to homeowners in Glendale, CA. Regular chimney maintenance is essential for the safety, efficiency, and longevity of your fireplace or stove. Our skilled technicians are dedicated to keeping your chimney in optimal condition, ensuring that it operates safely and efficiently year-round.
                                <br />
                                <br />
                            </h3>
                            <a href="/service/Chimney-Maintenance">Learn More</a>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={Chimney4} alt="Chimney Maintenance Glendale, CA" />
                        </div>


                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with Chimney Sweep Services in Glendale ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Reach Out to Us</h3>
                            <h4>Contact our expert team by phone or visit our website to schedule your Glendale Chimney Sweep service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide Your Details</h3>
                            <h4>Share your address and any specific details about your home that could impact the service, ensuring our team is well-prepared.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Help Us Understand Your Situation</h3>
                            <h4>Send us photos of your chimney to give us a clear view of its current condition, allowing us to provide an accurate service estimate.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Schedule Your Service</h3>
                            <h4>Discuss the urgency of your needs, and we’ll arrange a convenient date and time for our team to visit.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Receive a Customized Quote</h3>
                            <h4>Our local service provider will assess your requirements and provide a quote based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href="tel:747-349-8762">CALL NOW 747-349-8762</a>
                    <a href="/book">SCHEDULE ONLINE</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={Chimney2} alt="Chimney Sweep Near Me In Glendale, California" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <div className="MainBanner6Left">
                    <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                    <h3>Explore Our FAQs for Your Chimney Needs or Watch Our Latest Videos.</h3>
                    <a href="/faq">READ OUR CHIMNEY FAQS</a>
                </div>
                <div className="MainBanner6Right">
                    <img src={bgnobgs} alt="Chimney Sweep In Glendale, CA | Chimney Repair In Glendale, CA | Chimney Services In Glendale, CA | Chimney Inspection In Glendale, CA" />
                </div>
            </div>
            <div className="MainBanner7">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <div className="MainBanner7Maein">
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>I had an excellent experience with Glendale Chimney Sweep. The team was punctual, professional, and very thorough in their work. They not only cleaned my chimney but also provided valuable advice on maintaining it. I feel much safer knowing my chimney is in good hands!</h2>
                        <h4>John M.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>Glendale Chimney Sweep exceeded my expectations. Their technicians were friendly and knowledgeable, explaining each step of the inspection and cleaning process. They even found and fixed a small issue I wasn't aware of. I highly recommend their services to anyone in the area.</h2>
                        <h4>Sarah T.</h4>
                    </div>
                    <div className="MainBanner7Box">
                        <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                        <h2>I’ve used Glendale Chimney Sweep for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.</h2>
                        <h4>David R.</h4>
                    </div>
                </div>
                <a href={`/reviews/${LocationData.find(a => a.City == ("Glendale").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>MORE FROM OUR CUSTOMERS &gt;</a>
                </div>
            <div className="MainBanner4">
                <div className="MainBanner4Right">
                    <h1>OUR OUR CHIMNEY SERVICES</h1>
                    <h2>At Glendale Chimney Sweep, we provide a full range of chimney services, including cleaning, inspection, repair, and maintenance. Our certified technicians are dedicated to ensuring your chimney is safe, efficient, and in optimal condition. We use only high-quality materials and cutting-edge equipment to deliver outstanding results, enhancing both the safety and comfort of your home while giving you peace of mind.</h2>
                    <div className="MainBafnner4RightBenefits">
                        {AreaData.map((A, index) =>
                            <a href={A.Path} className="Header1BoxMenuBox">
                                {A.TitleShortVery}
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>

        </div>
    )
}

